import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Cancer.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Cancer Nature
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/cancer"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Cancer</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>             
              <h4 className="text-sm"></h4>

              <h4 className="text-sm">Cancer Nature</h4>
              <h4 className="mx-2 text-sm"></h4>             
              <h4 className="text-sm"></h4>


            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Cancer Nature </h1>
                <h2 className="text-sm md:text-base ml-4">Jun 22 - Jul 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-love");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-nature");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-man");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-traits");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-facts");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="font-bold text-orange-500 inline-block mr-2">Cancer Ruling Planet:</p>Moon <br/>
           The ruling planet, Moon indicates authority and control over self-emotion and drives the natives bizarre. The planet holds the power of human sentiments and is also reliable for changing emotions, feelings, and mood swings that residents experience in their cores. <br/>
           You maintain a great in-depth understanding of the feelings and emotions that you carry. You experience different stages when it comes to your life incidents. This is because you smoothly or we can say easily get influenced by your emotions due to the power of the Moon. You undertake the commitment and prioritize your emotions for your close and dear ones.<br/>
           <br></br>
           <p className="font-bold text-orange-500 inline-block mr-2">Ruling House:</p>Fourth (Security)<br/>
           The fourth house leads to security hence you always want to console in and get comfy. The house symbolizes the thing that gives consolation which is the house, memories of childhood, family, relatives, friends, and ancestors. You will have a hunger for security and amenity throughout life. <br/>
           <br></br>
           You love to live your life in your comfy home which acts as a protective coating for you like a shell. You feel satisfied, happy, safe, and secure there. Don’t miss the opportunity to know about your relationships in 2024! Get your Free Personalized 2024 Yearly Love and Relationship horoscope Report from our genuine astrologer.<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Habits</p><br/>
           You are one of those who always love to live life in their comfort zone hence you may be addicted to Television shows and web series. Due to this, you sometimes, miss out on other adventures. Sometimes you hold frustration and irritation for a long time which may lead to some pessimistic thinking.<br/>
           <br></br>
           You often grumble to an intense level about that. You are too sensitive and emotional during extreme sentiments which may lead to extra stress. You look for comfort to alleviate intense emotions. You are extremely attached to your memories and always try to make your moment memorable forever. You fade or disappear for some time into reproachful stillness when you are hurt.<br/>
           <br></br>
           <p className="font-bold text-orange-500 inline-block mr-2">Element:</p>Water<br/>
           You reminisce the deep inner well of human feelings and sentiments. You have the power to connect emotions to people, residents, and situations. You intuitively understand and learn how to make others nourished and protected. You can read the requirements of the people around you. <br/>
           <br></br>
           Inequality, conflict, and imbalance are major reasons of concern for you and it will be your utmost priority to resolve them. You appreciate small sentimental specialties in life. You take a good moment to process your ideas and thoughts. You seem to be a bit quieter and take a long time to comprehend your own emotions.<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Strengths</p><br/>
           Cancer natives are intensely loving people. They belong to the most nurturing and caring signs. You love safety and security hence take your companionships and connections very seriously. You are a gracious lover and unconditionally give your love, care, and life to the one you are profoundly attached to. You are very devoted to your close ones and do everything to support them.<br/>
           <br></br>
           You tend to find pleasure in sharing your residences, advice, and encouraging your close ones. You have a good sense of wit and discover humor even in serious and difficult situations. People are pleased and want to be in your company when things are not going well for them. You are fast to share, reliable, and value truthfulness the most. Individuals gravitate to you at the time of sorrow and sad occasions as you can sympathize with them in the best way. You will first listen to and understand their issues and grief. <br/>
           <br></br>
           <p>Hurry up!</p><br/>
           Get to know your special strengths and list down your drawback to turn them into your strengths too! Get your Free Janampatri and know your weaknesses and strengths!<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Weaknesses</p><br/>
           You desire attachment and protection so you become tacky and bossy sometimes. You always have the fear of desertion from your near and dear ones. You go to intense circumstances to get devotion and care from them. You find hardships in letting go of your past occasions and incidences. You are highly sensitive and emotional. <br/>
           <br></br>
           Hence, you ignore but never forgive those who have broken you. The scar of the miseries of the past stays with you for a longer time and confront difficulty to overcome it. You even rewind your life and stay back in the past to recall the pain and reopen the scar. You drove yourself into your shell when you face sorrow, hurt and insulting situations. You are quite grumpy and never reveal you are true emotions easily.<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Eminent Personality</p><br/>
           Khloe Kardashian, Selena Gomez, Olivia Munn, Solange Knowles, Tom Cruise, Tom Hanks, Meryl Streep, Ariana Grande, Princess Diana, Prince William, Robin Williams, George Michael, Nikola Tesla, Malala Yousafzai, 14th Dalai Lama, Pablo Neruda, Lizzy Caplan, Nicole Scherzinger, Lindsay Lohan, Priyanka Chopra, Lindsay Lohan, Katrina Kaif, Karishma Kapoor, Ariana Grande, Sofía Vergara, Ranveer Singh, Arjun Kapoor, Sooraj Pancholi, Naseeruddin Shah, Elon Musk, Lana Del Rey, Vin Diesel, Kristen Bell, Zayed Khan, Atul Agnihotri, Raj Babbar, Amrish Puri.<br/>
           <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
